import { api } from "../config/api";

export const adminService = {
  getAdmins: async () => api.get("/accounts/admins"),
  getAdmin: async (adminId) => api.get(`/accounts/admins/${adminId}`),
  addAdmin: async (payload) => api.post("/accounts/admins", payload),
  updateAdmin: async ({ adminId, payload }) =>
    api.put(`/accounts/admins/${adminId}`, payload),
deleteAdmin: async (adminId) => api.delete(`/accounts/admins/${adminId}`),
}
