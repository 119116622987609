import { api } from "../config/api";

export const gymnasiumService = {
  getGymnasiums: () => api.get("/gymnasiums"),
  getGymnasium: (gymnasiumId) => api.get(`/gymnasiums/${gymnasiumId}`),
  addGymnasium: (data) => api.post("/gymnasiums", data),
  getGymnasiumCalendar: (gymnasiumId) =>
    api.get(`/gymnasiums/${gymnasiumId}/recurring-reservable-slots`),
  updateGymnasium: (data) => {
    return api.put(`/gymnasiums/${data.gymnasiumId}`, data.payload);
  },
  updateGymnasiumCalendar: (data) => {
    return api.post(
      `/gymnasiums/${data.gymnasiumId}/recurring-reservable-slots`,
      data.payload
    );
  },
  addInstitution: (data) => {
    return api.post("/institutions", data);
  },
  addSportsClub: (data) => {
    return api.post("/sports-clubs", data);
  },
  getInstitutions: () => api.get("/institutions"),
  deleteInstitution: (institutionId) => {
    return api.delete("/institutions/" + institutionId);
  },
  getSportsClubs: () => api.get("/sports-clubs"),
  deleteSportsClub: (sportsClubId) => {
    return api.delete("/sports-clubs/" + sportsClubId);
  },
  getInstitution: (institutionId) => {
    return api.get("/institutions/" + institutionId);
  },
  updateInstitution: (data) => {
    return api.put(`/institutions/${data.institutionId}`, data.payload);
  },
  getSportsClub: (sportsClubId) => {
    return api.get("/sports-clubs/" + sportsClubId);
  },
  updateSportsClub: (data) => {
    return api.put(`/sports-clubs/${data.sportsClubId}`, data.payload);
  },
};
