import React from "react";
import {
  Button,
  Card,
  Table,
  Tabs,
  Modal,
  notification,
  Flex,
  Breadcrumb,
  Empty,
} from "antd";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { reservationService } from "../../../services/reservation";
import { Link } from "react-router-dom";

const { confirm } = Modal;
const { success, error } = notification;

function OutgoingReservations() {
  const {
    data: reservationsResponse,
    isLoading,
    refetch: getReservations,
  } = useQuery({
    queryKey: "reservations",
    queryFn: reservationService.getReservations,
  });

  const { mutate: cancelReservation } = useMutation({
    mutationFn: reservationService.cancelReservation,
    onSuccess: () => {
      success({
        description: "Rezervasyon iptal edildi.",
      });
      getReservations();
    },
    onError: (err) => {
      error({
        message: err.response?.data?.errors[0]?.message,
        description: "Rezervasyon iptal edilirken bir hata oluştu!",
      });
    },
  });

  const columns = [
    {
      title: "Rezervasyon Numarası",
      dataIndex: "reservationNo",
      key: "rezNo",
    },
    {
      title: "Tarih",
      key: "date",
      render: (record) => dayjs(record.date).format("DD MMMM YYYY"),
    },
    {
      title: "Saat",
      key: "time",
      render: (record) => `${record.startTime} - ${record.endTime}`,
    },
    {
      title: "İlçe",
      dataIndex: "districtName",
      key: "districtName",
    },
    {
      title: "Kurum",
      dataIndex: "institutionName",
      key: "institutionName",
    },
    {
      title: "Salon",
      dataIndex: "gymnasiumName",
      key: "gymnasiumName",
    },
    {
      title: "Branş",
      dataIndex: "sportsBranchName",
      key: "sportsBranchName",
    },
  ];

  const statusColumn = {
    title: "Durum",
    key: "status",
    render: (record) => {
      let statusMessage;
      switch (record.status) {
        case "PASSED":
          statusMessage = "Geçmiş";
          break;
        case "CANCELLED":
          statusMessage = "İptal Edildi";
          break;
      }
      return <span>{statusMessage}</span>;
    },
  };

  const items = [
    {
      key: "1",
      label: "Aktif Rezervasyonlar",
      children: (
        <Table
          dataSource={reservationsResponse?.data.reservations.filter(
            (reservation) => reservation.status === "CREATED"
          )}
          columns={[
            ...columns,
            {
              key: "actions",
              width: 200,
              render: (record) =>
                record.status === "CREATED" && (
                  <Button
                    type="primary"
                    danger
                    onClick={() =>
                      confirm({
                        title: "Rezervasyon İptali",
                        content:
                          "Rezervasyonu iptal etmek istediğinize emin misiniz?",
                        okText: "İptal Et",
                        cancelText: "Vazgeç",
                        onOk() {
                          cancelReservation(record.reservationNo);
                        },
                      })
                    }
                  >
                    Rezervasyonu İptal Et
                  </Button>
                ),
            },
          ]}
          scroll={{ x: 1500 }}
          loading={isLoading}
          locale={{
            emptyText: <Empty description="Aktif rezervasyon bulunamadı" />,
          }}
        />
      ),
    },
    {
      key: "2",
      label: "Geçmiş Rezervasyonlar",
      children: (
        <Table
          dataSource={reservationsResponse?.data.reservations.filter(
            (reservation) => reservation.status !== "CREATED"
          )}
          columns={[...columns, statusColumn]}
          scroll={{ x: 1500 }}
          loading={isLoading}
          locale={{
            emptyText: <Empty description="Geçmiş rezervasyon bulunamadı" />,
          }}
        />
      ),
    },
  ];

  return (
    <Flex vertical gap={20}>
      <Breadcrumb
        items={[
          {
            title: <Link to="/">Anasayfa</Link>,
          },
          {
            title: "Rezervasyonlarım",
          },
        ]}
      />
      <Card title="Rezervasyonlarım">
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </Flex>
  );
}

export default OutgoingReservations;
