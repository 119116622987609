import React from "react";
import { Col, Row} from "antd";
import styles from "./Forbidden.module.scss";

function Forbidden() {

  return (
    <div className={styles.login}>
      <div className={styles.overlay}></div>
      <div className={styles.container}>
        <Row>
          <Col md={12} xs={0}>
            <div className={styles.loginHero}>
              <h1>Sayfa görüntülenemiyor.</h1>
              <p>Bu sayfayı görüntülemek için yetkiniz bulunmamaktadır.</p>
              <a href="/">Ana Sayfaya Dön</a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Forbidden;
