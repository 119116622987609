import React, { useState } from "react";
import { Card, Form, Col, Row, Input, Button, Spin, notification } from "antd";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "antd/es/form/Form";
import { authService } from "../../services/auth";

const { success, error } = notification;

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const { mutate: changePassword } = useMutation({
    mutationFn: authService.changePassword,
    onSuccess: () => {
      success({
        message: "Başarılı!",
        description: "Şifreniz başarıyla değiştirildi."
      });
      form.resetFields();
      setLoading(false);
    },
    onError: (err) => {
      error({
        message: err.response?.data?.errors[0]?.message,
        description: "Şifreniz değiştirilirken bir hata oluştu!",
      });
      setLoading(false);
    }
  });

  const handleOnFinish = async (values) => {
    setLoading(true);
    changePassword(values);
  };

  return (
    <Spin spinning={loading}>
      <Form
        name="changePassword"
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleOnFinish}
      >
        <Card
          title="Şifremi Değiştir"
          extra={
            <Button
              htmlType="submit"
              type="primary"
              disabled={loading}
            >
              Değiştir
            </Button>
          }
        >
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                name="oldPassword"
                label="Mevcut Şifre"
                rules={[{ required: true, message: "Mevcut şifreyi giriniz." }]}
              >
                <Input.Password placeholder="Mevcut Şifre" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="newPassword"
                label="Yeni Şifre"
                rules={[
                  { required: true, message: "Yeni şifreyi giriniz." },
                  { min: 6, message: "Şifre en az 6 karakter olmalıdır." }
                ]}
              >
                <Input.Password placeholder="Yeni Şifre" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="confirmNewPassword"
                label="Yeni Şifre Tekrar"
                dependencies={["newPassword"]}
                hasFeedback
                rules={[
                  { required: true, message: "Yeni şifreyi tekrar giriniz." },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Şifreler birbiriyle eşleşmiyor.")
                      );
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Yeni Şifre Tekrar" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Spin>
  );
}

export default ChangePassword;
