import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Button,
  ConfigProvider,
  Form,
  Select,
  Modal,
  Spin,
  notification,
  Radio,
  Typography,
  Alert
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
import classNames from "classnames";
import { useMutation, useQuery } from "@tanstack/react-query";

import { reservationService } from "../../services/reservation";
import styles from "./Reservation.module.scss";
import { getUserInfo } from "../../utils/getUserInfo";
import { ROLES } from "../../constants/role";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/auth";

dayjs.extend(customParseFormat);

const { confirm } = Modal;
const { Text } = Typography;
const { success, error } = notification;

function Reservation() {
  const [form] = Form.useForm();
  const districts = Form.useWatch("districts", form);
  const branch = Form.useWatch("branch", form);
  const institution = Form.useWatch("institution", form);
  const gymnasium = Form.useWatch("gymnasium", form);
  const user = getUserInfo();
  const navigate = useNavigate();
  const [account, setAccount] = useState("");
  const [currentWeek, setCurrentWeek] = useState(0);
  const [role, setRole] = useState(user.role);

  useEffect(() => {
    authService.getAccount().then((response) => {
      setAccount(response.data);
    }).catch(err => {
      if (err.response?.status === 418) {
        localStorage.removeItem("token");
        window.location.href = "/giris-yap";
      }
    });
  }, []);

  const { data: districtsResponse } = useQuery({
    queryFn: reservationService.getDistricts,
    queryKey: "districts"
  });

  const { data: branchsResponse } = useQuery({
    queryFn: () => reservationService.getBranchs(role),
    queryKey: ["branches", role],
    enabled: !!role
  });

  const { data: institutionsResponse } = useQuery({
    queryFn: () => reservationService.getInstitutions(districts, branch),
    queryKey: [districts, branch],
    enabled: branch !== undefined && districts !== undefined
  });

  const { data: gymnasiumsResponse } = useQuery({
    queryFn: () => reservationService.getGymnasiums(institution, branch),
    queryKey: [institution, branch],
    enabled: institution !== undefined && branch !== undefined
  });

  const {
    data: calendarResponse,
    isLoading,
    refetch: getReservationSlots
  } = useQuery({
    queryFn: () => reservationService.getCalendar(gymnasium),
    queryKey: [gymnasium, "calendar"],
    enabled: institution !== undefined && branch !== undefined && gymnasium !== undefined
  });

  const { mutate: createReservation } = useMutation({
    mutationFn: reservationService.createReservation,
    onSuccess: () => {
      notification.success({
        message: "Rezervasyon Yapıldı."
      });
      navigate("/rezervasyonlar");
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Rezervasyon yapılırken bir hata oluştu!"
      });
    }
  });

  const showConfirm = (date, slot) => {
    confirm({
      title: "Rezervasyon işleminizi tamamlamak istiyor musunuz?",
      okText: "Rezervasyonu Tamamla",
      cancelText: "Vazgeç",
      okType: "primary",
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <Text>
            <Text strong>Kurum Adı </Text>: {getInstitutionName(institution)}
          </Text>
          <br />
          <Text>
            <Text strong>Spor Salonu </Text>: {getGymnasiumName(gymnasium)}
          </Text>
          <br />
          <Text>
            <Text strong>Branş</Text>: {getBranchName(branch)}
          </Text>

          <br />
          <Text>
            <Text strong>Rezervasyon Tarihi</Text>:{" "}
            {dayjs(date).format("DD MMMM YYYY")}
          </Text>
          <br />
          <Text>
            <Text strong>Rezervasyon Saati</Text>: {slot.startTime} -{" "}
            {slot.endTime}
          </Text>
        </div>
      ),
      onOk() {
        const role =
          user.role === ROLES.ADMIN ? ROLES.ADMIN : form.getFieldValue("role");
        createReservation({
          gymnasiumId: gymnasium,
          date,
          startTime: slot.startTime,
          endTime: slot.endTime,
          reservedToType: role,
          sportsBranchId: branch
        });
      }
    });
  };

  const handleOnFinish = (values) => {
    getReservationSlots();
  };

  const getBranchName = (branchId) => {
    const branchs = branchsResponse?.data.sportsBranches;
    const branch = branchs.find((branch) => branch.id === branchId);
    return branch?.name;
  };

  const getGymnasiumName = (gymnasiumId) => {
    const gymnasiums = gymnasiumsResponse?.data.gymnasiums;
    const gymnasium = gymnasiums.find(
      (gymnasium) => gymnasium.id === gymnasiumId
    );
    return gymnasium?.name;
  };

  const getInstitutionName = (institutionId) => {
    const institutions = institutionsResponse?.data.institutions;
    const institution = institutions.find(
      (institution) => institution.id === institutionId
    );
    return institution?.name;
  };

  const { mutate: cancelReservation } = useMutation({
    mutationFn: reservationService.cancelReservation,
    onSuccess: () => {
      success({
        description: "Rezervasyon iptal edildi."
      });
      getReservationSlots();
    },
    onError: (err) => {
      error({
        message: err.response?.data?.errors[0]?.message,
        description: "Rezervasyon iptal edilirken bir hata oluştu!"
      });
    }
  });

  const weeks = [];
  if (calendarResponse?.data?.reservationSlots?.length) {
    for (let i = 0; i < calendarResponse.data.reservationSlots.length; i += 7) {
      weeks.push(calendarResponse.data.reservationSlots.slice(i, i + 7));
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {},
          Form: {
            labelHeight: 14
          }
        }
      }}
    >
      <Form
        name="basic"
        layout="vertical"
        autoComplete="off"
        onFinish={handleOnFinish}
        form={form}
        initialValues={{ role: user.role }}
      >
        <div>
          <div>
            <Form.Item name="role">
              <Radio.Group onChange={(e) => setRole(e.target.value)}> {/* Capture the radio button value */}
                <>
                  {
                    account.hasSportsClub && (
                      <Radio value={ROLES.SPORTS_CLUB}>Spor Kulübü</Radio>
                    )
                  }
                  {
                    account.hasInstitution && (
                      <Radio value={ROLES.INSTITUTION}>Okul Takımı</Radio>
                    )
                  }
                </>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className={styles.search}>
            <h3>Spor Salonu Rezervasyonu</h3>

            <div className={styles.searchActions}>
              <div className={styles.inputs}>
                <Form.Item
                  className={styles.formItem}
                  name="districts"
                  rules={[{ required: true, message: "Lütfen ilçe seçiniz." }]}
                >
                  <Select
                    showSearch
                    className={styles.select}
                    size="small"
                    placeholder="İlçe Seçiniz"
                    options={districtsResponse?.data.districts.map(
                      (district) => ({
                        value: district.id,
                        label: district.name
                      })
                    )}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    mode="multiple"
                    variant="borderless"
                    onChange={() => {
                      form.resetFields(["institution", "gymnasium"]);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.formItem}
                  name="branch"
                  rules={[{ required: true, message: "Branş seçiniz." }]}
                >
                  <Select
                    showSearch
                    className={styles.select}
                    placeholder="Branş Seçiniz"
                    options={branchsResponse?.data.sportsBranches.map(
                      (branch) => ({
                        value: branch.id,
                        label: branch.name
                      })
                    )}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    variant="borderless"
                    onChange={() => {
                      form.resetFields(["institution", "gymnasium"]);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.formItem}
                  name="institution"
                  rules={[{ required: true, message: "Lütfen kurum seçiniz." }]}
                >
                  <Select
                    showSearch
                    className={styles.select}
                    placeholder="Kurum Seçiniz"
                    options={institutionsResponse?.data.institutions.map(
                      (institution) => ({
                        value: institution.id,
                        label: institution.name
                      })
                    )}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={() => {
                      form.resetFields(["gymnasium"]);
                    }}
                    variant="borderless"
                  />
                </Form.Item>
                <Form.Item
                  className={styles.formItem}
                  name="gymnasium"
                  rules={[{ required: true, message: "Lütfen salon seçiniz." }]}
                >
                  <Select
                    showSearch
                    className={styles.select}
                    placeholder="Salon Seçiniz"
                    options={gymnasiumsResponse?.data.gymnasiums.map(
                      (gymnasium) => ({
                        value: gymnasium.id,
                        label: gymnasium.name
                      })
                    )}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    variant="borderless"
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.searchBtn}
                >
                  Ara
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      {calendarResponse && gymnasium && (
        <>
          {gymnasiumsResponse.data.gymnasiums.find(x => x.id === gymnasium).note && (
            <Alert message={gymnasiumsResponse.data.gymnasiums.find(x => x.id === gymnasium).note} type={"warning"} />
          )}
        </>
      )}

      {weeks.length > 0 && (
        <div className={styles.weekNavigation}>
          <Button
            onClick={() => setCurrentWeek((prev) => Math.max(prev - 1, 0))}
            disabled={currentWeek === 0}
          >
            Önceki Hafta
          </Button>
          <Button
            onClick={() => setCurrentWeek((prev) => Math.min(prev + 1, weeks.length - 1))}
            disabled={currentWeek === weeks.length - 1}
          >
            Sonraki Hafta
          </Button>
        </div>
      )}

      <Spin tip="Aranıyor.." spinning={isLoading}>
        {calendarResponse?.data && (
          <div className={styles.calendar}>
            {weeks[currentWeek]?.map((range, index) => (
              <div className={styles.calendarDate} key={index}>
                <div className={styles.header}>
                  {dayjs(range.date, "YYYY-MM-DD").format("D MMMM")}
                </div>
                <div className={styles.slots}>
                  {range.timeSlots.map((slot, slotIndex) => (
                    <div
                      className={classNames(
                        styles.slot,
                        slot.status !== "AVAILABLE" && styles.notAvailable
                      )}
                      key={slotIndex}
                    >
                      <div className={styles.label}>
                        {slot.startTime} - {slot.endTime}
                      </div>
                      {user.role === ROLES.ADMIN && (
                        <div className={styles.branch}>
                          {slot.reservedTo === "SPORTS_CLUB" && "Okul Kulübü"}
                          {slot.reservedTo === "INSTITUTION" && "Okul"}
                          {slot.reservedTo === "ADMIN" && "Yönetici"}
                          &nbsp;
                        </div>
                      )}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          onClick={() => {
                            if (slot.status === "AVAILABLE") {
                              showConfirm(range.date, slot);
                            } else if (slot.status === "RESERVED" && user.role === ROLES.ADMIN) {
                              confirm({
                                title: "Rezervasyon İptali",
                                content: "Rezervasyonu iptal etmek istediğinize emin misiniz?",
                                okText: "Rezervasyonu İptal Et",
                                okButtonProps: { danger: true, type: "primary" },
                                okType: "danger",
                                cancelText: "Vazgeç",
                                onOk() {
                                  cancelReservation(slot.reservationId);
                                }
                              });
                            }
                          }}
                          type={
                            slot.status === "AVAILABLE"
                              ? "primary"
                              : slot.status === "RESERVED" && user.role === ROLES.ADMIN
                                ? "danger"
                                : "default"
                          }
                          disabled={slot.status !== "AVAILABLE" && !(slot.status === "RESERVED" && user.role === ROLES.ADMIN)}
                          className={slot.status === "RESERVED" && user.role === ROLES.ADMIN ? styles.redButton : ""}
                        >
                          {slot.status === "AVAILABLE"
                            ? "Rezervasyon Yap"
                            : slot.status === "RESERVED"
                              ? user.role === ROLES.ADMIN
                                ? "Rezervasyonu İptal Et"
                                : "Rezerve Edildi"
                              : "Kapalı"}
                        </Button>

                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </ConfigProvider>
  );
}

export default Reservation;
