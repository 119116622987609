import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gymnasiumService } from "../../services/gymnasiums";
import { reservationService } from "../../services/reservation";
import styles from "../Reservation/Reservation.module.scss";
import { getUserInfo } from "../../utils/getUserInfo";

function EditInstitution() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { institutionId } = useParams();
  const [form] = useForm();
  const [showSportsClubCard, setShowSportsClubCard] = useState(false);
  const [isSportsClubEmpty, setIsSportsClubEmpty] = useState(true);
  const user = getUserInfo();

  const { data: branchsResponse } = useQuery({
    queryFn: () => reservationService.getBranchs(user.role),
    queryKey: ["branchs", user.role],
  });

  const { data: districtsResponse } = useQuery({
    queryFn: reservationService.getDistricts,
    queryKey: "districts",
  });

  const {
    data: getInstitutionResponse,
    isLoading: getInstitutionLoading,
    refetch: getInstitution,
    isError,
  } = useQuery({
    queryFn: () => gymnasiumService.getInstitution(institutionId),
    queryKey: ["institution", institutionId],
    enabled: !!institutionId,
  });

  const { mutate: updateInstitution } = useMutation({
    mutationFn: gymnasiumService.updateInstitution,
    onSuccess: () => {
      notification.success({
        message: "Kurum güncellendi.",
      });
      setLoading(true);
      getInstitution();
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Kurum güncellenirken bir hata oluştu!",
      });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleOnFinish = (values) => {
    const {
      institutionName,
      phoneNumber,
      fullAddress,
      districtId,
      sportsClubName,
      licenseNumber,
      sportsBranches,
    } = values;
    const payload = {
      institutionId,
      name: institutionName,
      phoneNumber,
      fullAddress,
      districtId,
    };
    setLoading(true);

    if (isSportsClubEmpty && sportsClubName !== undefined) {
      payload.sportsClub = {
        name: sportsClubName,
        licenseNumber: licenseNumber,
        sportsBranchIds: sportsBranches,
      };
    }
    updateInstitution({ institutionId, payload });
  };

  useEffect(() => {
    const institution = getInstitutionResponse?.data;
    if (institution) {
      form.setFieldsValue({
        ...institution,
        institutionName: institution.name,
        districtId: institution.district.id,
        ...institution.sportsClub,
        sportsClubName: institution.sportsClub?.name,
        sportsBranches: institution.sportsClub?.branches.map((item) => item.id),
      });
      const hasSportsClub =
        institution.sportsClub &&
        Object.keys(institution.sportsClub).length > 0;
      setShowSportsClubCard(hasSportsClub);
      setIsSportsClubEmpty(!hasSportsClub);
    }
  }, [form, getInstitutionResponse?.data]);

  useEffect(() => {
    if (isError) {
      notification.error({
        message: "Kurum getirilirken bir hata oluştu.",
        description: "Sistemde bir hata oluştu. Lütfen tekrar deneyin.",
      });
      navigate("/kurum-spor-duzenleme");
    }
  }, [isError, navigate]);

  const handleToggleSportsClubCard = () => {
    setShowSportsClubCard(!showSportsClubCard);
  };

  return (
    <Spin spinning={loading || getInstitutionLoading}>
      <Flex vertical gap={20}>
        <Breadcrumb
          items={[
            {
              title: <Link to="/">Anasayfa</Link>,
            },
            {
              title: <Link to="/kurum-spor-duzenleme">Kurumlar</Link>,
            },
            {
              title: "Kurum Güncelle",
            },
          ]}
        />

        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={handleOnFinish}
        >
          <Card
            title="Kurum Bilgileri"
            extra={
              <Button htmlType="submit" type="primary">
                Kaydet
              </Button>
            }
          >
            <Row justify="space-between">
              <Col span={4}>
                <Form.Item
                  name="districtId"
                  label="İlçe"
                  rules={[{ required: true, message: "İlçe seçiniz." }]}
                >
                  <Select
                    showSearch
                    className={styles.select}
                    placeholder="İlçe Seçiniz"
                    options={districtsResponse?.data.districts.map(
                      (district) => ({
                        value: district.id,
                        label: district.name,
                      })
                    )}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="institutionName"
                  label="Kurum Adı"
                  rules={[{ required: true, message: "Kurum adı giriniz." }]}
                >
                  <Input placeholder="Kurum adı" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="phoneNumber"
                  label="İrtibat Numarası"
                  rules={[
                    {
                      required: true,
                      message: "İrtibat numarası giriniz.",
                    },
                    {
                      pattern: /^0\d{10}$/,
                      message:
                        "Lütfen 11 haneli bir irtibat numarası giriniz. Örnek: 05555555555",
                    },
                  ]}
                >
                  <Input
                    placeholder="İrtibat Numarası"
                    maxLength={11}
                    type="tel"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="fullAddress"
                  label="Adres"
                  rules={[{ required: true, message: "Adres giriniz." }]}
                >
                  <Input placeholder="Adres" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="email"
                  label="E-Posta"
                  rules={[
                    { required: true, message: "E-posta giriniz." },
                    { type: "email", message: "Geçerli bir e-posta giriniz." },
                  ]}
                >
                  <Input placeholder="E-Posta" disabled />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          {!showSportsClubCard && (
            <Button
              type="primary"
              onClick={handleToggleSportsClubCard}
              style={{ marginTop: 10 }}
            >
              {"Bu kurum için spor kulübü ekle"}
            </Button>
          )}

          {showSportsClubCard && (
            <Card
              style={{ marginTop: 20 }}
              title="Spor Kulübü Bilgileri"
              extra={
                isSportsClubEmpty ? (
                  <Button
                    type="text"
                    onClick={handleToggleSportsClubCard}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      padding: "10px 8px",
                      fontSize: "12px",
                    }}
                  >
                    X
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate(
                        `/kulup-duzenleme/${getInstitutionResponse?.data?.sportsClub?.id}`
                      )
                    }
                  >
                    Spor Kulübünü Düzenle
                  </Button>
                )
              }
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    label="Branş"
                    name="sportsBranches"
                    rules={[{ required: true, message: "Branş seçiniz" }]}
                  >
                    <Select
                      showSearch
                      className={styles.select}
                      placeholder="Branş Seçiniz"
                      options={branchsResponse?.data.sportsBranches.map(
                        (branch) => ({
                          value: branch.id,
                          label: branch.name,
                        })
                      )}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                      disabled={!isSportsClubEmpty}
                    />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    label="Kulüp Numarası"
                    name="licenseNumber"
                    rules={[
                      { required: true, message: "Kulüp numarası giriniz." },
                    ]}
                  >
                    <Input
                      placeholder="Kulüp Numarası"
                      disabled={!isSportsClubEmpty}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Kulüp Adı"
                    name="sportsClubName"
                    rules={[{ required: true, message: "Kulüp adı giriniz." }]}
                  >
                    <Input
                      placeholder="Kulüp Adı"
                      disabled={!isSportsClubEmpty}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          )}
        </Form>
      </Flex>
    </Spin>
  );
}

export default EditInstitution;
