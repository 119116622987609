import {
  Breadcrumb,
  Button,
  Card,
  Flex,
  Spin,
  Tooltip,
  notification
} from "antd";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

import styles from "./GymnasiumCalendar.module.scss";
import { gymnasiumService } from "../../services/gymnasiums";
import { useEffect, useState } from "react";

const days = [
  "Pazartesi",
  "Salı",
  "Çarşamba",
  "Perşembe",
  "Cuma",
  "Cumartesi",
  "Pazar"
];

function GymnasiumCalendar() {
  const [slots, setSlots] = useState([]);
  const [isUpdateLoading, setUpdateIsLoading] = useState(false);

  const { gymnasiumId } = useParams();

  const { data: getGymnasiumCalendarRes, isLoading } = useQuery({
    queryFn: () => gymnasiumService.getGymnasiumCalendar(gymnasiumId),
    queryKey: ["gymnasiumCalendar", gymnasiumId]
  });

  const { mutate: updateGymnasiumCalendar } = useMutation({
    mutationKey: "updateGymnasiumCalendar",
    mutationFn: gymnasiumService.updateGymnasiumCalendar,
    onSuccess: () => {
      notification.success({
        message: "Takvim güncellendi."
      });
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Takvim güncellenirken bir hata oluştu!"
      });
    },
    onSettled: () => {
      setUpdateIsLoading(false);
    }
  });

  const handleCalendarUpdate = () => {
    setUpdateIsLoading(true);
    const payload = slots.reduce((acc, item) => {
      item.timeSlots.forEach((slot) => {
        if (slot.isAvailable) {
          acc.push({
            dayOfWeek: item.dayOfWeek,
            startTime: slot.startTime,
            endTime: slot.endTime
          });
        }
      });
      return acc;
    }, []);
    updateGymnasiumCalendar({
      gymnasiumId,
      payload
    });
  };

  const gymnasiumName = getGymnasiumCalendarRes?.data.gymnasium.name;

  const toogleSlot = (slot, slotIndex) => {
    slots[slotIndex].timeSlots = slots[slotIndex].timeSlots.map((item) =>
      item.startTime === slot.startTime
        ? {
          ...item,
          isAvailable: !item.isAvailable
        }
        : item
    );
    setSlots([...slots]);
  };

  useEffect(() => {
    if (getGymnasiumCalendarRes?.data) {
      setSlots(getGymnasiumCalendarRes?.data.availableTimes);
    }
  }, [getGymnasiumCalendarRes]);

  if (isLoading) {
    return <Spin spinning fullscreen />;
  }

  return (
    <Flex vertical gap={20}>
      <Breadcrumb
        items={[
          {
            title: <Link to="/">Anasayfa</Link>
          },
          {
            title: <Link to="/spor-salonu">Spor Salonları</Link>
          },
          {
            title: "Spor Salonu Takvimi Düzenle"
          }
        ]}
      />
      <Card
        title={`${gymnasiumName} Takvimi`}
        extra={
          <Button type="primary" onClick={handleCalendarUpdate}>
            Kaydet
          </Button>
        }
      >
        <Spin spinning={isUpdateLoading}>
          <div className={styles.calendar}>
            {slots?.map((item, slotIndex) => (
              <div key={item.day} className={styles.calendarDay}>
                <div className={styles.header}>
                  <h3>{days[item.dayOfWeek - 1]}</h3>
                </div>
                <div className={styles.slots}>
                  {item.timeSlots.map((slot) => (
                    <div
                      className={classNames(
                        styles.slotWrapper,
                        slot.isAvailable ? styles.available : styles.unavailable
                      )}
                    >
                      <div key={slot.startTime} className={styles.slot}>
                        <span>
                          {slot.startTime} - {slot.endTime}
                        </span>
                      </div>
                      <Tooltip
                        mouseEnterDelay={0.5}
                        title={
                          slot.isAvailable ? "Takvime Kapat" : "Takvime Aç"
                        }
                      >
                        <Button
                          onClick={() => toogleSlot(slot, slotIndex)}
                          icon={
                            !slot.isAvailable ? (
                              <CheckCircleOutlined />
                            ) : (
                              <CloseCircleOutlined />
                            )
                          }
                        />
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Spin>
      </Card>
    </Flex>
  );
}

export default GymnasiumCalendar;
