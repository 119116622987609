import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Col,
  Row,
  Input,
  Button,
  Spin,
  notification,
  Breadcrumb
} from "antd";
import { useMutation } from "@tanstack/react-query";
import { adminService } from "../../services/admin";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

function AddAdmin() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { adminId } = useParams();
  const [form] = useForm();

  const { mutate: addAdmin } = useMutation({
    mutationFn: adminService.addAdmin,
    onSuccess: () => {
      notification.success({
        message: "Yönetici hesabı eklendi."
      });
      navigate("/yoneticiler");
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Yönetici hesabı eklenirken bir hata oluştu!",
      });
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  const { mutate: updateAdmin } = useMutation({
    mutationFn: adminService.updateAdmin,
    onSuccess: () => {
      notification.success({
        message: "Yönetici hesabı güncellendi."
      });
      navigate("/yoneticiler");
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Yönetici hesabı güncellenirken bir hata oluştu!",
      });
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  const handleOnFinish = (values) => {
    const { name, email, phoneNumber } = values;
    const payload = { name, email, phoneNumber };
    setLoading(true);
    if (adminId) {
      updateAdmin({ adminId, payload });
    } else {
      addAdmin(payload);
    }
  };

  useEffect(() => {
    if (adminId) {
      adminService.getAdmin(adminId).then((response) => {
        const admin = response.data;
        form.setFieldsValue(admin);
      }).catch(() => {
        notification.error({
          message: "Yönetici hesabı getirilirken bir hata oluştu.",
          description: "Sistemde bir hata oluştu. Lütfen tekrar deneyin."
        });
        navigate("/yoneticiler");
      });
    }
  }, [form, adminId, navigate]);

  return (
    <Spin spinning={loading}>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Breadcrumb
          items={[
            {
              title: <Link to="/">Anasayfa</Link>
            },
            {
              title: <Link to="/yoneticiler">Yönetici Hesapları</Link>
            },
            {
              title: adminId ? "Yönetici Hesabı Güncelle" : "Yönetici Hesabı Ekle"
            }
          ]}
        />
        <Form form={form} layout="vertical" onFinish={handleOnFinish}>
          <Card
            title={`${adminId ? "Yönetici Hesabı Güncelle" : "Yönetici Hesabı Ekle"}`}
            extra={
              <Button htmlType="submit" type="primary">
                Kaydet
              </Button>
            }
          >
            <Row gutter={10}>
              <Col md={8} xs={24}>
                <Form.Item
                  name="name"
                  label="İsim"
                  rules={[{ required: true, message: "İsim giriniz." }]}
                >
                  <Input placeholder="İsim" />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name="email"
                  label="E-posta"
                  rules={[
                    { required: true, message: "E-posta giriniz." },
                    { type: "email", message: "Geçerli bir e-posta giriniz." }
                  ]}
                >
                  <Input placeholder="E-posta" />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name="phoneNumber"
                  label="İrtibat Numarası"
                  rules={[
                    {
                      required: true,
                      message: "İrtibat numarası giriniz."
                    },
                    {
                      pattern: /^0\d{10}$/,
                      message: "Lütfen 11 haneli bir irtibat numarası giriniz. Örnek: 05555555555"
                    }
                  ]}
                >
                  <Input placeholder="İrtibat Numarası" maxLength={11} type="tel" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
    </Spin>
  );
}

export default AddAdmin;
