import { Button, Card, Table, Empty } from "antd";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { gymnasiumService } from "../../services/gymnasiums";

function CheckInInstitutions() {
  const navigate = useNavigate();
  const [activeTabKey1, setActiveTabKey1] = useState("tab3");

  const { data: institutionsResponse, isLoading: isInstitutionsLoading, refetch: getInstitutions } = useQuery({
    queryKey: "institutions",
    queryFn: gymnasiumService.getInstitutions
  });

  const InstitutionsTable = ({ institutionsResponse, isInstitutionsLoading }) => {
    const institutionsColumns = [
      {
        title: "Kurum Id",
        dataIndex: "id",
        key: "id"
      },
      {
        title: "Kurum Adı",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "İlçe",
        dataIndex: "district",
        key: "district"
      },
      {
        title: "Telefon Numarası",
        key: "phoneNumber",
        dataIndex: "phoneNumber"
      },
      {
        title: "E-posta",
        key: "institutionEmail",
        dataIndex: "email"
      }
    ];

    const dataSource = institutionsResponse?.data?.institutions || [];

    return (
      <Table
        dataSource={dataSource}
        columns={[
          ...institutionsColumns,
          {
            key: "actions",
            width: 200,
            render: (record) => (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  type="primary"
                  onClick={() => navigate(`/check-in-raporu/${record.id}`)}
                >
                  Raporu Görüntüle
                </Button>
              </div>
            )
          }
        ]}
        scroll={{ x: 1500 }}
        loading={isInstitutionsLoading}
        locale={{
          emptyText: <Empty description="Görüntülenecek kurum yok" />,
        }}
      />
    );
  };

  return (
    <Card
      style={{ width: "100%" }}
      title="Kurum Listesi"
      activeTabKey={activeTabKey1}
      onTabChange={setActiveTabKey1}
    >
      <InstitutionsTable
        isInstitutionsLoading={isInstitutionsLoading}
        institutionsResponse={institutionsResponse}
      />
    </Card>
  );
}

export default CheckInInstitutions;
