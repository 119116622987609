import AuthLayout from "./layouts/AuthLayout";
import AddGymnasium from "./pages/AddGymnasium";
import Gymnasiums from "./pages/Gymnasiums";
import Login from "./pages/Login";
import Reservation from "./pages/Reservation";
import OutgoingReservations from "./pages/Reservations/OutgoingReservations";
import EditAndListInstitutionAndSportsClub from "./pages/EditAndListInstitutionAndSportsClub/EditAndListInstitutionAndSportsClub";
import QRCode from "./pages/QRCode";
import GymnasiumCalendar from "./pages/GymnasiumCalendar";
import { Navigate } from "react-router-dom";
import { ROLES } from "./constants/role";
import IncomingReservations from "./pages/Reservations/IncomingReservations/IncomingReservations";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import AddAdmin from "./pages/AddAdmin";
import Admins from "./pages/Admins";
import EditInstitution from "./pages/EditInstitution";
import EditSportsClub from "./pages/EditSportsClub";
import ReservationsForCheckIn from "./pages/Reservations/ReservationsForCheckIn";
import CheckIns from "./pages/CheckIns";
import CheckInInstitutions from "./pages/CheckInInstitutions";
import Forbidden from "./pages/Forbidden";

const PublicRoute = ({ children }) => {
  if (localStorage.getItem("token") !== null) {
    return <Navigate to="/" />;
  }
  return children;
};

export const routers = [
  {
    path: "giris-yap",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    )
  },
  {
    path: "/",
    element: (
      <AuthLayout
        allowedRoles={[ROLES.ADMIN, ROLES.INSTITUTION, ROLES.SPORTS_CLUB]}
      >
        <Reservation />
      </AuthLayout>
    )
  },
  {
    path: "/rezervasyonlar",
    element: (
      <AuthLayout
        allowedRoles={[ROLES.ADMIN, ROLES.INSTITUTION, ROLES.SPORTS_CLUB]}
      >
        <OutgoingReservations />
      </AuthLayout>
    )
  },
  {
    path: "/spor-salonu/rezervasyonlar/:gymnasiumId",
    element: (
      <AuthLayout allowedRoles={[ROLES.INSTITUTION]}>
        <IncomingReservations />
      </AuthLayout>
    )
  },
  {
    path: "/spor-salonu",
    element: (
      <AuthLayout allowedRoles={[ROLES.INSTITUTION]}>
        <Gymnasiums />
      </AuthLayout>
    )
  },
  {
    path: "/spor-salonu-ekle",
    element: (
      <AuthLayout allowedRoles={[ROLES.INSTITUTION]}>
        <AddGymnasium />
      </AuthLayout>
    )
  },
  {
    path: "/spor-salonu/:gymnasiumId",
    element: (
      <AuthLayout allowedRoles={[ROLES.INSTITUTION]}>
        <AddGymnasium />
      </AuthLayout>
    )
  },
  {
    path: "/spor-salonu/takvim/:gymnasiumId",
    element: (
      <AuthLayout allowedRoles={[ROLES.INSTITUTION]}>
        <GymnasiumCalendar />
      </AuthLayout>
    )
  },
  {
    path: "/kurum-spor-duzenleme",
    element: (
      <AuthLayout allowedRoles={[ROLES.ADMIN]}>
        <EditAndListInstitutionAndSportsClub />
      </AuthLayout>
    )
  },
  {
    path: "/kurum-duzenleme/:institutionId",
    element: (
      <AuthLayout allowedRoles={[ROLES.ADMIN]}>
        <EditInstitution />
      </AuthLayout>
    )
  },
  {
    path: "/kulup-duzenleme/:sportsClubId",
    element: (
      <AuthLayout allowedRoles={[ROLES.ADMIN]}>
        <EditSportsClub />
      </AuthLayout>
    )
  },
  {
    path: "/karekod-olustur",
    element: (
      <AuthLayout allowedRoles={[ROLES.INSTITUTION]}>
        <QRCode />
      </AuthLayout>
    )
  },
  {
    path: "/giris-kayit",
    element: (
      <AuthLayout
        allowedRoles={[ROLES.ADMIN, ROLES.INSTITUTION, ROLES.SPORTS_CLUB]}
      >
        <ReservationsForCheckIn />
      </AuthLayout>
    )
  },
  {
    path: "/sifremi-unuttum",
    element: (
      <PublicRoute>
        <ForgotPassword />
      </PublicRoute>
    )
  },
  {
    path: "/sifremi-sifirla",
    element: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    )
  },
  {
    path: "/sifremi-degistir",
    element: (
      <AuthLayout
        allowedRoles={[ROLES.ADMIN, ROLES.INSTITUTION, ROLES.SPORTS_CLUB]}
      >
        <ChangePassword />
      </AuthLayout>
    )
  },
  {
    path: "/yonetici-ekle",
    element: (
      <AuthLayout allowedRoles={[ROLES.ADMIN, ROLES.INSTITUTION, ROLES.SPORTS_CLUB]}>
        <AddAdmin />
      </AuthLayout>
    )
  },
  {
    path: "/yonetici-duzenle/:adminId",
    element: (
      <AuthLayout allowedRoles={[ROLES.ADMIN, ROLES.INSTITUTION, ROLES.SPORTS_CLUB]}>
        <AddAdmin />
      </AuthLayout>
    )
  },
  {
    path: "/yoneticiler",
    element: (
      <AuthLayout allowedRoles={[ROLES.ADMIN]}>
        <Admins />
      </AuthLayout>
    )
  },
  {
    path: "/check-in-raporu/:institutionId",
    element: (
      <AuthLayout allowedRoles={[ROLES.INSTITUTION, ROLES.ADMIN]}>
        <CheckIns />
      </AuthLayout>
    )
  },
  {
    path: "/check-in-raporu",
    element: (
      <AuthLayout allowedRoles={[ROLES.INSTITUTION, ROLES.ADMIN]}>
        <CheckIns />
      </AuthLayout>
    )
  },
  {
    path: "/check-in-raporlari",
    element: (
      <AuthLayout allowedRoles={[ROLES.ADMIN]}>
        <CheckInInstitutions />
      </AuthLayout>
    )
  },
  {
    path: "/403",
    element: (
        <Forbidden />
    )
  }
];
