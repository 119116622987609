import React from "react";
import {
  Button,
  Card,
  Table,
  Modal,
  notification,
  Flex,
  Breadcrumb,
  Empty,
} from "antd";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { checkinService } from "../../../services/checkin";
import { Link, useSearchParams } from "react-router-dom";

const { confirm } = Modal;
const { success, error } = notification;

function ReservationsForCheckIn() {
  const [searchParams] = useSearchParams();

  const {
    data: reservationsResponse,
    isLoading,
    refetch: getReservations,
  } = useQuery({
    queryKey: [
      "reservations",
      searchParams.get("institutionId"),
      searchParams.get("code"),
    ],
    queryFn: () =>
      checkinService.reservationsForCheckIn(
        searchParams.get("institutionId"),
        searchParams.get("code")
      ),
  });

  const { mutate: checkinReservation } = useMutation({
    mutationFn: (data) => checkinService.checkin(data),
    onSuccess: () => {
      success({
        description: "Check-in başarılı.",
      });
      getReservations();
    },
    onError: (err) => {
      error({
        message: err.response?.data?.errors[0]?.message,
        description: "Check-in işlemi sırasında bir hata oluştu!",
      });
    },
  });

  const columns = [
    {
      title: "Saat",
      key: "time",
      render: (record) => (
        <>
          {record.sportsBranchName}
          <br />
          {record.startTime}
          <br /> {record.endTime}
        </>
      ),
    },
  ];

  return (
    <Flex vertical gap={20}>
      <Breadcrumb
        items={[
          {
            title: <Link to="/">Anasayfa</Link>,
          },
          {
            title: "Check-in Yapılabilecek Rezervasyonlar",
          },
        ]}
      />
      <Card
        title={
          dayjs(Date()).format("DD MMMM YYYY") + " Tarihine Ait Rezervasyonlar"
        }
      >
        <Table
          dataSource={reservationsResponse?.data?.reservations}
          columns={[
            ...columns,
            {
              key: "actions",
              width: 200,
              render: (record) => (
                <Button
                  type="primary"
                  onClick={() =>
                    confirm({
                      title: "Check-in Yap",
                      content: "Check-in yapmak istediğinize emin misiniz?",
                      okText: "Evet",
                      cancelText: "Vazgeç",
                      onOk() {
                        checkinReservation({
                          institutionId: parseInt(
                            searchParams.get("institutionId")
                          ),
                          code: searchParams.get("code"),
                          reservationId: record.reservationNo,
                        });
                      },
                    })
                  }
                >
                  Check-in Yap
                </Button>
              ),
            },
          ]}
          scroll={{ x: 50 }}
          loading={isLoading}
          locale={{
            emptyText: <Empty description="Check-in yapılabilecek rezervasyon bulunamadı" />,
          }}
        />
      </Card>
    </Flex>
  );
}

export default ReservationsForCheckIn;
