import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";

import { routers } from "../../routers";
import { queryClient } from "../../config/react-query";

export default function App() {
  const router = createBrowserRouter(routers);
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}
