import React from "react";
import { Table, Card, Button, Tooltip, Flex, Breadcrumb, Empty } from "antd";
import { EditOutlined, CalendarOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { gymnasiumService } from "../../services/gymnasiums";

function Gymnasiums() {
  const navigate = useNavigate();
  const { data: gymnasiumsResponse, isLoading } = useQuery({
    queryFn: gymnasiumService.getGymnasiums,
    queryKey: "myGymnasiums"
  });

  const columns = [
    {
      title: "Salon Adı",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Branş",
      key: "branch",
      render: (data) => data.sportsBranches.join(", ")
    },
    {
      title: "Not",
      dataIndex: "note",
      key: "note"
    },
    {
      render: (data) => (
        <Flex gap={10} justify="right">
          <Tooltip title="Düzenle">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => navigate(`/spor-salonu/${data.id}`)}
            />
          </Tooltip>
          <Tooltip title="Takvim Düzenle">
            <Button
              type="primary"
              icon={<CalendarOutlined />}
              onClick={() => navigate(`/spor-salonu/takvim/${data.id}`)}
            />
          </Tooltip>
          <Button
            type="primary"
            onClick={() => navigate(`/spor-salonu/rezervasyonlar/${data.id}`)}
          >
            Rezervasyonları Görüntüle
          </Button>
        </Flex>
      )
    }
  ];

  return (
    <Flex gap={20} vertical>
      <Breadcrumb
        items={[
          {
            title: <Link to="/">Anasayfa</Link>
          },
          {
            title: "Spor Salonları"
          }
        ]}
      />
      <Card
        title="Spor Salonları"
        extra={
          <Button type="primary" href="/spor-salonu-ekle">
            Spor Salonu Ekle
          </Button>
        }
      >
        <Table
          loading={isLoading}
          dataSource={gymnasiumsResponse?.data.gymnasiums}
          columns={columns}
          locale={{
            emptyText: <Empty description="Görüntülenecek spor salonu yok" />,
          }}
        />
      </Card>
    </Flex>
  );
}

export default Gymnasiums;
