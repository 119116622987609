import { Link, Navigate, useLocation } from "react-router-dom";
import { Menu, Layout, ConfigProvider, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import logo from "../../assets/images/logo-white.png";
import styles from "./AuthLayout.module.scss";
import { getUserInfo } from "../../utils/getUserInfo";
import { ROLES } from "../../constants/role";
import { authService } from "../../services/auth";

const { Header, Content } = Layout;

function AuthLayout({ allowedRoles, children }) {
  const { pathname, search } = useLocation();
  const user = getUserInfo();
  const [username, setUsername] = useState("");

  useEffect(() => {
    authService.getAccount().then((response) => {
      setUsername(response.data.name);
    }).catch(err => {
      if (err.response?.status === 418) {
        localStorage.removeItem("token");
        window.location.href = "/giris-yap";
      }
    });
  }, []);

  if (localStorage.getItem("token") === null) {
    const cbUrl = pathname + search;
    let loginUrl = "/giris-yap";

    if (cbUrl) {
      loginUrl += `?cb=${encodeURIComponent(cbUrl)}`;
    }

    return <Navigate to={loginUrl} />;
  }

  const items = [
    {
      key: 1,
      label: <Link to="/">Rezervasyon Oluştur</Link>,
      path: "/",
      allowedRoles: [ROLES.ADMIN, ROLES.INSTITUTION, ROLES.SPORTS_CLUB]
    },
    {
      key: 4,
      label: <Link to="/rezervasyonlar">Rezervasyonlar</Link>,
      path: "/rezervasyonlar",
      allowedRoles: [ROLES.ADMIN, ROLES.INSTITUTION, ROLES.SPORTS_CLUB]
    },
    {
      key: 2,
      label: (
        <Link to="/kurum-spor-duzenleme">Kurum & Spor Kulübü Düzenleme</Link>
      ),
      path: "/kurum-spor-duzenleme",
      allowedRoles: [ROLES.ADMIN]
    },
    {
      key: 3,
      label: <Link to="/yoneticiler">Yöneticiler</Link>,
      path: "/yoneticiler",
      allowedRoles: [ROLES.ADMIN]
    },
    {
      key: 7,
      label: <Link to="/check-in-raporu">Check-in Raporu</Link>,
      path: "/check-in-raporu",
      allowedRoles: [ROLES.INSTITUTION]
    },
    {
      key: 7,
      label: <Link to="/check-in-raporlari">Check-in Raporları</Link>,
      path: "/check-in-raporlari",
      allowedRoles: [ROLES.ADMIN]
    }
  ];

  if (user.role === ROLES.INSTITUTION) {
    items.push({
      label: "Spor Salonu Yönetimi",
      allowedRoles: [ROLES.INSTITUTION],
      children: [
        {
          key: 5,
          label: <Link to="/spor-salonu">Spor Salonu Yönet</Link>,
          path: "/spor-salonu",
          allowedRoles: [ROLES.INSTITUTION]
        },
        {
          key: 6,
          label: <Link to="/karekod-olustur">Karekod Oluştur</Link>,
          path: "/karekod-olustur",
          allowedRoles: [ROLES.INSTITUTION]
        }
      ]
    });
  }

  if (!allowedRoles.includes(user.role)) {
    return <Navigate to="/403" />;
  }

  const userMenu = (
    <Menu>
      <Menu.Item key="change-password">
        <Link to="/sifremi-degistir">Şifremi Değiştir</Link>
      </Menu.Item>
      <Menu.Item
        key="logout"
        onClick={() => {
          localStorage.removeItem("token");
          window.location.href = "/giris-yap";
        }}
      >
        Çıkış Yap
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header className={styles.header}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                darkItemSelectedBg: "transparent",
                activeBarBorderWidth: 1
              }
            }
          }}
        >
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            className={styles.menu}
          >
            {items.map((item) => {
              if (item.children) {
                return (
                  <Menu.SubMenu key={item.key} title={item.label}>
                    {item.children.map((child) =>
                      child.allowedRoles.includes(user.role) ? (
                        <Menu.Item key={child.key}>
                          <Link to={child.path}>{child.label}</Link>
                        </Menu.Item>
                      ) : null
                    )}
                  </Menu.SubMenu>
                );
              }

              return item.allowedRoles.includes(user.role) ? (
                <Menu.Item key={item.key}>
                  <Link to={item.path}>{item.label}</Link>
                </Menu.Item>
              ) : null;
            })}
          </Menu>
        </ConfigProvider>
        <Dropdown overlay={userMenu} className={styles.userDropdown}>
          <span>
            {username} <DownOutlined />
          </span>
        </Dropdown>
      </Header>
      <Content className={styles.content}>{children}</Content>
    </Layout>
  );
}

export default AuthLayout;
