import React, { useEffect, useState } from "react";
import {
  Card,
  Select,
  Form,
  Col,
  Row,
  Input,
  Button,
  Spin,
  notification,
  Breadcrumb,
  Flex,
} from "antd";

import { useMutation, useQuery } from "@tanstack/react-query";
import { gymnasiumService } from "../../services/gymnasiums";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { reservationService } from "../../services/reservation";
import { getUserInfo } from "../../utils/getUserInfo";

function AddGymnasium() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { gymnasiumId } = useParams();
  const [form] = useForm();
  const user = getUserInfo();

  const { mutate: addGymnasium } = useMutation({
    mutationFn: gymnasiumService.addGymnasium,
    onSuccess: (response) => {
      notification.success({
        message: "Spor salonu eklendi.",
      });
      navigate(`/spor-salonu/takvim/${response.data.id}`);
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Spor salonu eklenirken bir hata oluştu!",
      });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { data: branchsResponse } = useQuery({
    queryFn: () => reservationService.getBranchs(user.role),
    queryKey: ["branchs", user.role],
  });

  const {
    data: getGymnasiumResponse,
    isLoading: getGymnasiumLoading,
    isError,
  } = useQuery({
    queryFn: () => gymnasiumService.getGymnasium(gymnasiumId),
    queryKey: ["gymnasium", gymnasiumId],
    enabled: !!gymnasiumId,
  });

  const { mutate: updateGymnasium } = useMutation({
    mutationFn: gymnasiumService.updateGymnasium,
    onSuccess: () => {
      notification.success({
        message: "Spor salonu güncellendi.",
      });
      navigate("/spor-salonu");
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Spor salonu güncellenirken bir hata oluştu!",
      });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleOnFinish = (values) => {
    const { name, phoneNumber, note, sportsBranches } = values;
    const payload = {
      name,
      phoneNumber,
      note,
      sportsBranchIds: sportsBranches,
    };
    setLoading(true);
    if (gymnasiumId) {
      updateGymnasium({ gymnasiumId, payload });
    } else {
      addGymnasium(payload);
    }
  };

  useEffect(() => {
    const gymnasium = getGymnasiumResponse?.data;
    if (gymnasium) {
      form.setFieldsValue({
        ...gymnasium,
        sportsBranches: gymnasium.sportsBranches.map((item) => item.id),
      });
    }
  }, [form, getGymnasiumResponse?.data]);

  useEffect(() => {
    if (isError) {
      notification.error({
        message: "Spor salonu getirilirken bir hata oluştu.",
        description: "Sistemde bir hata oluştu. Lütfen tekrar deneyin.",
      });
      navigate("/spor-salonu");
    }
  }, [isError, navigate]);

  return (
    <Spin spinning={loading || getGymnasiumLoading}>
      <Flex vertical gap={20}>
        <Breadcrumb
          items={[
            {
              title: <Link to="/">Anasayfa</Link>,
            },
            {
              title: <Link to="/spor-salonu">Spor Salonları</Link>,
            },
            {
              title: gymnasiumId ? "Spor Salonu Güncelle" : "Spor Salonu Ekle",
            },
          ]}
        />
        <Form form={form} layout="vertical" onFinish={handleOnFinish}>
          <Card
            title={`${
              gymnasiumId ? "Spor Salonu Güncelle" : "Spor Salonu Ekle"
            }`}
            extra={
              <Button htmlType="submit" type="primary">
                Kaydet
              </Button>
            }
          >
            <Row gutter={10}>
              <Col md={24} xs={24}>
                <Form.Item
                  name="name"
                  label="Salon Adı"
                  rules={[
                    { required: true, message: "Spor salonu adı giriniz." },
                  ]}
                >
                  <Input placeholder="Spor Salonu Adı" />
                </Form.Item>
              </Col>
              <Col span={12} md={12} xs={24}>
                <Form.Item
                  name="sportsBranches"
                  label="Branş"
                  rules={[{ required: true, message: "Branş seçiniz." }]}
                >
                  <Select mode="multiple" showSearch placeholder="Branş Seçiniz" options={branchsResponse?.data.sportsBranches.map(
                    (branch) => ({
                      value: branch.id,
                      label: branch.name,
                    })
                  )}
                          filterOption={(input, option) =>
                            option?.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }/>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="phoneNumber"
                  label="İrtibat Numarası"
                  rules={[
                    {
                      required: true,
                      message: "İrtibat numarası giriniz.",
                    },
                    {
                      pattern: /^0\d{10}$/,
                      message:
                        "Lütfen 11 haneli bir irtibat numarası giriniz. Örnek: 05555555555",
                    },
                  ]}
                >
                  <Input
                    placeholder="İrtibat Numarası"
                    maxLength={11}
                    type="tel"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="note" label="Not">
                  <Input.TextArea placeholder="Not" rows={5} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Flex>
    </Spin>
  );
}

export default AddGymnasium;
