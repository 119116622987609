import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React, { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { gymnasiumService } from "../../services/gymnasiums";
import { reservationService } from "../../services/reservation";
import styles from "../Reservation/Reservation.module.scss";
import { getUserInfo } from "../../utils/getUserInfo";

const tabList = [
  {
    key: "tab1",
    tab: "Kurum Ekle",
  },
  {
    key: "tab2",
    tab: "Spor Kulübü Ekle",
  },
  {
    key: "tab3",
    tab: "Kurum Listesi",
  },
  {
    key: "tab4",
    tab: "Spor Kulübü Listesi",
  },
];

const { confirm } = Modal;
const { success, error } = notification;

function EditAndListInstitutionAndSportsClub() {
  const user = getUserInfo();
  const navigate = useNavigate();
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const [loading, setLoading] = useState(false);
  const [showSportsClubCard, setShowSportsClubCard] = useState(false);

  const [form] = useForm();
  const sportsBranches = Form.useWatch("sportsBranches", form);
  const { data: districtsResponse } = useQuery({
    queryFn: reservationService.getDistricts,
    queryKey: "districts",
  });
  const { data: branchsResponse, isLoading: isBranchsLoading } = useQuery({
    queryFn: () => reservationService.getBranchs(user.role),
    queryKey: ["branchs", user.role],
  });
  const { mutate: addInstitution } = useMutation({
    mutationFn: gymnasiumService.addInstitution,
    onSuccess: (res) => {
      notification.success({
        message: "Kurum eklendi.",
      });
      form.resetFields();
      getInstitutions();
      setActiveTabKey1("tab3");
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Kurum eklenirken bir hata oluştu!",
      });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: addSportsClub } = useMutation({
    mutationFn: gymnasiumService.addSportsClub,
    onSuccess: () => {
      notification.success({
        message: "Spor kulübü eklendi.",
      });
      form.resetFields();
      getSportsClubs();
      setActiveTabKey1("tab4");
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Spor kulübü eklenirken bir hata oluştu!",
      });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleOnFinish = (values) => {
    setLoading(true);
    const {
      institutionEmail,
      name,
      phoneNumber,
      fullAddress,
      districtId,
      sportsClubName,
      licenseNumber,
      sportsBranches,
    } = values;
    const data = {
      name,
      phoneNumber,
      fullAddress,
      districtId,
      email: institutionEmail,
    };

    if (showSportsClubCard) {
      data.sportsClub = {
        name: sportsClubName,
        licenseNumber: licenseNumber,
        sportsBranchIds: sportsBranches,
      };
    }
    addInstitution(data);
  };

  const handleOnSaveSportsClub = (values) => {
    const { sportsClubEmail, phoneNumber, name, licenseNumber } = values;
    const payload = {
      email: sportsClubEmail,
      phoneNumber,
      name,
      licenseNumber,
      sportsBranchIds: sportsBranches,
    };
    setLoading(true);
    addSportsClub(payload);
  };

  const {
    data: institutionsResponse,
    isLoading: isInstitutionsLoading,
    refetch: getInstitutions,
  } = useQuery({
    queryKey: "institutions",
    queryFn: gymnasiumService.getInstitutions,
  });

  const { mutate: deleteInstitutions } = useMutation({
    mutationFn: gymnasiumService.deleteInstitution,
    onSuccess: () => {
      success({
        description: "Kurum silindi.",
      });
      getInstitutions();
    },
    onError: (err) => {
      error({
        message: err.response?.data?.errors[0]?.message,
        description: "Kurum silinirken bir hata oluştu!",
      });
    },
  });

  const InstitutionsTable = ({
                               institutionsResponse,
                               isInstitutionsLoading,
                             }) => {
    const institutionsColumns = [
      {
        title: "Kurum Id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Kurum Adı",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "İlçe",
        dataIndex: "district",
        key: "district",
      },
      {
        title: "Telefon Numarası",
        key: "phoneNumber",
        dataIndex: "phoneNumber",
      },
      {
        title: "E-posta",
        key: "email",
        dataIndex: "email",
      },
    ];

    const dataSource = institutionsResponse?.data?.institutions || [];

    return (
      <Table
        dataSource={dataSource}
        columns={[
          ...institutionsColumns,
          {
            key: "actions",
            width: 200,
            render: (record) => (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  type="primary"
                  onClick={() => navigate(`/kurum-duzenleme/${record.id}`)}
                >
                  Kurum Düzenle
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() =>
                    confirm({
                      title: "Kurumu Sil",
                      content: "Kurumu silmek istediğinize emin misiniz?",
                      okText: "Sil",
                      cancelText: "Vazgeç",
                      onOk() {
                        deleteInstitutions(record.id);
                      },
                    })
                  }
                >
                  Kurumu Sil
                </Button>
              </div>
            ),
          },
        ]}
        scroll={{ x: 1500 }}
        loading={isInstitutionsLoading}
      />
    );
  };

  const {
    data: sportsClubsResponse,
    isLoading: isSportsClubsLoading,
    refetch: getSportsClubs,
  } = useQuery({
    queryKey: "sportsClubs",
    queryFn: gymnasiumService.getSportsClubs,
  });

  const { mutate: deleteSportsClub } = useMutation({
    mutationFn: gymnasiumService.deleteSportsClub,
    onSuccess: () => {
      success({
        description: "Spor kulübü silindi.",
      });
      getSportsClubs();
    },
    onError: (err) => {
      error({
        message: err.response?.data?.errors[0]?.message,
        description: "Spor kulübü silinirken bir hata oluştu!",
      });
    },
  });

  const SportsClubsTable = ({ sportsClubsResponse, isSportsClubsLoading }) => {
    const sportsClubsColumns = [
      {
        title: "Kulüp Id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Kulüp Adı",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Lisans Numarası",
        dataIndex: "licenseNumber",
        key: "licenseNumber",
      },
      {
        title: "Telefon Numarası",
        key: "phoneNumber",
        dataIndex: "phoneNumber",
      },
      {
        title: "E-posta",
        key: "email",
        dataIndex: "email",
      },
      {
        title: "Branşlar",
        key: "branches",
        dataIndex: "branches",
        render: (branches) => branches.join(", "),
      },
      {
        title: "Bağlı Olduğu Kurum",
        key: "institutionName",
        dataIndex: "institutionName",
      },
    ];

    const dataSource = sportsClubsResponse?.data?.sportsClubs || [];

    return (
      <Table
        dataSource={dataSource}
        columns={[
          ...sportsClubsColumns,
          {
            key: "actions",
            width: 200,
            render: (record) => (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  type="primary"
                  onClick={() => navigate(`/kulup-duzenleme/${record.id}`)}
                >
                  Kulüp Düzenle
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() =>
                    confirm({
                      title: "Spor Kulübünü Sil",
                      content:
                        "Spor kulübünü silmek istediğinize emin misiniz?",
                      okText: "Sil",
                      cancelText: "Vazgeç",
                      onOk() {
                        deleteSportsClub(record.id);
                      },
                    })
                  }
                >
                  Spor Kulübünü Sil
                </Button>
              </div>
            ),
          },
        ]}
        scroll={{ x: 1500 }}
        loading={isSportsClubsLoading}
      />
    );
  };

  const handleToggleSportsClubCard = () => {
    setShowSportsClubCard(!showSportsClubCard);
  };

  const contentList = {
    tab1: (
      <Spin spinning={loading}>
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={handleOnFinish}
        >
          <Card
            title="Kurum Bilgileri"
            extra={
              <Button htmlType="submit" type="primary">
                Ekle
              </Button>
            }
          >
            <Row justify="space-between">
              <Col span={4}>
                <Form.Item
                  name="districtId"
                  label="İlçe"
                  rules={[{ required: true, message: "İlçe seçiniz." }]}
                >
                  <Select
                    showSearch
                    className={styles.select}
                    size="small"
                    placeholder="İlçe Seçiniz"
                    options={districtsResponse?.data.districts.map(
                      (district) => ({
                        value: district.id,
                        label: district.name,
                      })
                    )}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="name"
                  label="Kurum Adı"
                  rules={[{ required: true, message: "Kurum adı giriniz." }]}
                >
                  <Input placeholder="Kurum adı" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="phoneNumber"
                  label="İrtibat Numarası"
                  rules={[
                    {
                      required: true,
                      message: "İrtibat numarası giriniz.",
                    },
                    {
                      pattern: /^0\d{10}$/,
                      message:
                        "Lütfen 11 haneli bir irtibat numarası giriniz. Örnek: 05555555555",
                    },
                  ]}
                >
                  <Input
                    placeholder="İrtibat Numarası"
                    maxLength={11}
                    type="tel"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="fullAddress"
                  label="Adres"
                  rules={[{ required: true, message: "Adres giriniz." }]}
                >
                  <Input placeholder="Adres" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="institutionEmail"
                  label="E-Posta"
                  rules={[
                    { required: true, message: "E-posta giriniz." },
                    { type: "email", message: "Geçerli bir e-posta giriniz." },
                  ]}
                >
                  <Input placeholder="E-Posta" />
                </Form.Item>
              </Col>
            </Row>
            {!showSportsClubCard && (
              <Row>
                <Col span={1}>
                  <Button onClick={handleToggleSportsClubCard} type="primary">
                    Bu kurum icin spor kulübü ekle
                  </Button>
                </Col>
              </Row>
            )}
          </Card>
          {showSportsClubCard && (
            <Card
              style={{ marginTop: 20 }}
              title="Spor Kulübü Bilgileri"
              extra={
                <Button
                  type="text"
                  onClick={handleToggleSportsClubCard}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    padding: "10px 8px",
                    fontSize: "12px",
                  }}
                >
                  X
                </Button>
              }
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    label="Branş"
                    name="sportsBranches"
                    rules={[{ required: true, message: "Branş seçiniz." }]}
                  >
                    <Select
                      showSearch
                      className={styles.select}
                      placeholder="Branş Seçiniz"
                      options={branchsResponse?.data.sportsBranches.map(
                        (branch) => ({
                          value: branch.id,
                          label: branch.name,
                        })
                      )}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    label="Kulüp Numarası"
                    name="licenseNumber"
                    rules={[
                      { required: true, message: "Kulüp numarası giriniz." },
                    ]}
                  >
                    <Input placeholder="Kulüp Numarası" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Kulüp Adı"
                    name="sportsClubName"
                    rules={[{ required: true, message: "Kulüp adı giriniz." }]}
                  >
                    <Input placeholder="Kulüp Adı" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          )}
        </Form>
      </Spin>
    ),

    tab2: (
      <Spin spinning={loading}>
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={handleOnSaveSportsClub}
        >
          <Card
            title="Spor Kulübü Bilgileri"
            extra={
              <Button htmlType="submit" type="primary">
                Kaydet
              </Button>
            }
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="Branş"
                  name="sportsBranches"
                  rules={[{ required: true, message: "Branş seçiniz." }]}
                >
                  <Select
                    showSearch
                    className={styles.select}
                    placeholder="Branş Seçiniz"
                    options={branchsResponse?.data.sportsBranches.map(
                      (branch) => ({
                        value: branch.id,
                        label: branch.name,
                      })
                    )}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label="Kulüp Numarası"
                  name="licenseNumber"
                  rules={[
                    { required: true, message: "Kulüp numarası giriniz." },
                  ]}
                >
                  <Input placeholder="Kulüp Numarası" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Kulüp Adı"
                  name="name"
                  rules={[{ required: true, message: "Kulüp adı giriniz." }]}
                >
                  <Input placeholder="Kulüp Adı" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="phoneNumber"
                  label="İrtibat Numarası"
                  rules={[
                    {
                      required: true,
                      message: "İrtibat numarası giriniz.",
                    },
                    {
                      pattern: /^0\d{10}$/,
                      message:
                        "Lütfen 11 haneli bir irtibat numarası giriniz. Örnek: 05555555555",
                    },
                  ]}
                >
                  <Input
                    placeholder="İrtibat Numarası"
                    maxLength={11}
                    type="tel"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="sportsClubEmail"
                  label="E-Posta"
                  rules={[
                    { required: true, message: "E-posta giriniz." },
                    { type: "email", message: "Geçerli bir e-posta giriniz." },
                  ]}
                >
                  <Input placeholder="E-Posta" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Spin>
    ),
    tab3: (
      <InstitutionsTable
        isInstitutionsLoading={isInstitutionsLoading}
        institutionsResponse={institutionsResponse}
      />
    ),
    tab4: (
      <SportsClubsTable
        isSportsClubsLoading={isSportsClubsLoading}
        sportsClubsResponse={sportsClubsResponse}
      />
    ),
  };

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
    if (key === "tab4") {
      getSportsClubs();
    }
    if (key === "tab3") {
      getInstitutions();
    }
  };

  return (
    <Card
      style={{
        width: "100%",
      }}
      title="Kurum & Spor Kulübü Düzenleme"
      tabList={tabList}
      activeTabKey={activeTabKey1}
      onTabChange={onTab1Change}
    >
      {contentList[activeTabKey1]}
    </Card>
  );
}

export default EditAndListInstitutionAndSportsClub;
