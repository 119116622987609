import {
  Breadcrumb,
  Button,
  Card,
  Flex,
  Popconfirm,
  Spin,
  notification,
} from "antd";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";

import styles from "./QRCode.module.scss";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { checkinService } from "../../services/checkin";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

function CreateQRCode() {
  const [isReGenerateLoading, setIsReGenerateLoading] = useState(false);
  const [renewedQRCode, setRenewedQRCode] = useState(null);

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Spor Salonu Karekod",
    removeAfterPrint: true,
  });

  const {
    data: getCheckinInfoRes,
    isLoading: isCheckinInfoLoading,
    isError,
  } = useQuery({
    queryKey: "checkinInfo",
    queryFn: checkinService.getCheckinInfo,
  });

  const { mutate: reGenerateQRCode } = useMutation({
    mutationKey: "reGenerateQRCode",
    mutationFn: checkinService.reGenerateQRCode,
    onSuccess: (res) => {
      setRenewedQRCode(res.data);
      notification.success({
        message: "Karekod Oluşturuldu",
        description: "Yeni karekod başarıyla oluşturuldu.",
      });
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Karekod oluşturulurken bir hata oluştu",
      });
    },
    onSettled: () => {
      setIsReGenerateLoading(false);
    },
  });

  useEffect(() => {
    if (isError) {
      notification.error({
        message: "Karekod Oluşturulamadı",
        description: "Karekod oluşturulurken bir hata meydana geldi.",
      });
    }
  }, [isError]);

  const qrCodeInfo = renewedQRCode || getCheckinInfoRes?.data;

  return (
    <Flex vertical gap={20}>
      <Breadcrumb
        items={[
          {
            title: <Link to="/">Anasayfa</Link>,
          },
          {
            title: "Karekod Oluştur",
          },
        ]}
      />
      <Card
        title="Karekod Oluştur"
        extra={
          <Flex gap={10}>
            <Popconfirm
              okText="Yeniden Oluştur"
              cancelText="Kapat"
              title="Karekod yeniden oluşturulacak, onaylıyor musunuz?"
              placement="bottom"
              description={
                <div className={styles.reGenerateQRCodeConfirm}>
                  Karekod yeniden oluşturulduktan sonra eski karekod ile spor
                  salonuna giriş yapılamaz, yeni karekodu yazdırıp kullanıcıya
                  vermelisiniz.
                </div>
              }
              onConfirm={() => {
                setIsReGenerateLoading(true);
                reGenerateQRCode();
              }}
            >
              <Button type="primary">Yeniden Oluştur</Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={() => handlePrint(null, () => contentToPrint.current)}
            >
              Yazdır
            </Button>
          </Flex>
        }
      >
        <Spin
          spinning={isCheckinInfoLoading || isReGenerateLoading}
          tip="Karekod yükleniyor..."
        >
          <div className={styles.qrcodePrintArea} ref={contentToPrint}>
            {qrCodeInfo && (
              <>
                <div className={styles.qrCodeWrapper}>
                  <h5>
                    Karekod Oluşturma Tarihi:{" "}
                    {dayjs(qrCodeInfo.createdAt, "DD-MM-YYYY hh:mm").format(
                      "DD MMMM YYYY HH:mm"
                    )}
                  </h5>
                  <QRCode
                    value={`${process.env.REACT_APP_DOMAIN}/giris-kayit?institutionId=${qrCodeInfo.institutionId}&code=${qrCodeInfo.uuid}`}
                  />
                </div>

                <h3>
                  Ekrandaki karekodu okutarak spor salonu giriş kaydınızı
                  yaptırabilirsiniz.
                </h3>
              </>
            )}
          </div>
        </Spin>
      </Card>
    </Flex>
  );
}

export default CreateQRCode;
