import React, { useState } from "react";
import { Col, Row, Button, Form, Input, notification } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../services/auth";
import styles from "./Login.module.scss";
import logo from "../../assets/images/logo.png";

function Login() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutate: login } = useMutation({
    mutationFn: authService.login,
    onSuccess: (res) => {
      const cb = searchParams.get("cb");
      const navigatePath = cb ? decodeURIComponent(cb) : "/";
      localStorage.setItem("token", res.data?.token);
      setLoading(false);
      navigate(navigatePath);
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Giriş işlemi sırasında bir hata oluştu!",
      });
      setLoading(false);
    },
  });

  const onFinish = (values) => {
    setLoading(true);
    login(values);
  };

  return (
    <div className={styles.login}>
      <div className={styles.overlay}></div>
      <div className={styles.container}>
        <Row>
          <Col md={12} xs={0}>
            <div className={styles.loginHero}>
              <h1>Hoş geldiniz</h1>
              <p>İstanbul İl Milli Eğitim Müdürlüğü</p>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className={styles.loginFormWrapper}>
              <div className={styles.loginForm}>
                <div className={styles.logo}>
                  <img src={logo} alt="logo" />
                </div>
                <h3 className={styles.title}> SPOR SALONU REZERVASYONU</h3>
                <div className={styles.formWrapper}>
                  <Form
                    name="login"
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="email"
                      label="E-Posta Adresi"
                      type="email"
                      rules={[
                        { required: true, message: "E-posta giriniz." },
                        {
                          type: "email",
                          message: "Geçerli bir e-posta giriniz.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Şifre"
                      rules={[{ required: true, message: "Şifre giriniz." }]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <div className={styles.forgotPasswordContainer}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Link
                          to="/sifremi-unuttum"
                          className={styles.forgotPassword}
                        >
                          Şifremi Unuttum
                        </Link>
                      </Form.Item>
                    </div>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                      >
                        Giriş Yap
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Login;
