import React from "react";
import { Table, Card, Button, Tooltip, Breadcrumb, Modal, Empty } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { adminService } from "../../services/admin";
import { queryClient } from "../../config/react-query";

const { confirm } = Modal;

function Admins() {
  const navigate = useNavigate();
  const { data: adminsResponse, isLoading } = useQuery({
    queryFn: adminService.getAdmins,
    queryKey: "myAdmins"
  });

  const columns = [
    {
      title: "İsim",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "E-posta",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "İrtibat Numarası",
      dataIndex: "phoneNumber",
      key: "phoneNumber"
    },
    {
      render: (data) => (
        <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
          <Tooltip title="Düzenle">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => navigate(`/yonetici-duzenle/${data.id}`)}
            />
            &nbsp;
            <Button
              type="primary"
              danger
              onClick={() =>
                confirm({
                  title: "Yönetici Hesabını Sil",
                  content:
                    "Yönetici hesabını silmek istediğinize emin misiniz?",
                  okText: "Sil",
                  cancelText: "Vazgeç",
                  onOk() {
                    adminService.deleteAdmin(data.id).then(() => {
                      queryClient.invalidateQueries("myAdmins");
                    })
                  },
                })
              }
            >
              Admini Sil
            </Button>
          </Tooltip>
        </div>
      )
    }
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Breadcrumb
        items={[
          {
            title: <Link to="/">Anasayfa</Link>
          },
          {
            title: "Yöneticiler"
          }
        ]}
      />
      <Card
        title="Yöneticiler"
        extra={
          <Button type="primary" href="/yonetici-ekle">
            Yönetici Hesabı Ekle
          </Button>
        }
      >
        <Table
          loading={isLoading}
          dataSource={adminsResponse?.data.admins}
          columns={columns}
          locale={{
            emptyText: <Empty description="Görüntülenecek yönetici yok" />,
          }}
        />
      </Card>
    </div>
  );
}

export default Admins;
