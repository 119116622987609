import React from "react";
import ReactDOM from "react-dom/client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/tr";

import App from "./components/App";
import "./styles/main.scss";

dayjs.locale("tr");

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Istanbul");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
