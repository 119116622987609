import { api } from "../config/api";

export const authService = {
  login: async ({ email, password }) =>
    api.post("/accounts/login", { email, password }),
  forgotPassword: async ({ email }) =>
    api.post("/accounts/send-reset-password-email", { email }),
  resetPassword: async ({ password, token }) =>
    api.post("/accounts/reset-password", { password, token }),
  changePassword: async ({ oldPassword, newPassword }) =>
    api.post("/accounts/change-password", { oldPassword, newPassword }),
  getAccount: async () => api.get("/accounts"),
};
