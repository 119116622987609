import { api } from "../config/api";

export const reservationService = {
  getDistricts: () => api.get("/search/districts"),
  getBranchs: (reservedTo) => api.get(`/search/sports-branches?reservedTo=${reservedTo}`),
  getInstitutions: (districtIds, sportsBranchId) =>
    api.get("/search/institutions", {
      params: {
        districtIds: districtIds.join(","),
        sportsBranchId: sportsBranchId,
      },
    }),
  getGymnasiums: (institutionsId, sportsBranchId) =>
    api.get(`/search/institutions/${institutionsId}/gymnasiums`, {
      params: {
        sportsBranchId: sportsBranchId,
      },
    }),
  getCalendar: (gymnasiumId) =>
    api.get("/reservations/slots", {
      params: {
        gymnasiumId,
      },
    }),
  getReservations: () => api.get("/reservations/outgoing"),
  getIncomingReservations: () => api.get("/reservations/incoming"),
  getGymnasiumReservations: (gymnasiumId) => api.get(`/gymnasiums/${gymnasiumId}/reservations`),
  cancelReservation: (rezervationId) =>
    api.post(`/reservations/${rezervationId}/cancel`),
  createReservation: (data) => api.post("/reservations", data),
};
