import React, { useState } from "react";
import { Col, Row, Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../services/auth";
import styles from "./ForgotPassword.module.scss";
import logo from "../../assets/images/logo.png";

function ForgotPassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { mutate: forgotPassword } = useMutation({
    mutationFn: authService.forgotPassword,
    onSuccess: () => {
      notification.success({
        message: "Başarılı!",
        description: "Şifre sıfırlama bağlantısı e-posta adresinize gönderildi."
      });
      setLoading(false);
      // Redirect to login page or home page after successful submission
      navigate("/");
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Şifre sıfırlama sırasında bir hata oluştu!",
      });
      setLoading(false);
    }
  });

  const onFinish = (values) => {
    setLoading(true);
    forgotPassword(values);
  };

  return (
    <div className={styles.forgotPassword}>
      <div className={styles.overlay}></div>
      <div className={styles.container}>
        <Row>
          <Col md={12} xs={0}>
            <div className={styles.forgotPasswordHero}>
              <h1>Hoş geldiniz</h1>
              <p>İstanbul İl Milli Eğitim Müdürlüğü</p>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className={styles.forgotPasswordFormWrapper}>
              <div className={styles.forgotPasswordForm}>
                <div className={styles.logo}>
                  <img src={logo} alt="logo" />
                </div>
                <h3 className={styles.title}>ŞİFREMİ SIFIRLA</h3>
                <div className={styles.formWrapper}>
                  <Form
                    name="forgotPassword"
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="email"
                      label="E-Posta Adresi"
                      type="email"
                      rules={[
                        { required: true, message: "E-posta giriniz." },
                        { type: "email", message: "Geçerli bir e-posta giriniz." }
                      ]}>
                      <Input />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                      >
                        Şifremi Sıfırla
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ForgotPassword;
