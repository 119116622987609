import React from "react";
import { Breadcrumb, Card, Flex, Table, Empty } from "antd";
import dayjs from "dayjs";
import { checkinService } from "../../services/checkin";
import { Link, useParams } from "react-router-dom";
import { getUserInfo } from "../../utils/getUserInfo";
import { ROLES } from "../../constants/role";
import { useQuery } from "@tanstack/react-query";

function CheckIns() {
  const { institutionId } = useParams();
  const user = getUserInfo();

  const {
    data: checkInsResponse,
    isLoading,
  } = useQuery({
    queryKey: ["checkins"],
    queryFn: () => checkinService.checkins(institutionId === undefined ? null : institutionId)
  });

  const columns = [
    {
      title: "Rezervasyon Numarası",
      dataIndex: "reservationNo",
      key: "rezNo"
    },
    {
      title: "Tarih",
      key: "date",
      render: (record) => dayjs(record.date).format("DD MMMM YYYY")
    },
    {
      title: "Saat",
      key: "time",
      render: (record) => `${record.startTime} - ${record.endTime}`
    },
    {
      title: "Rezervasyon Sahibi",
      dataIndex: "reservationOwner",
      key: "reservationOwner"
    },
    {
      title: "Salon",
      dataIndex: "gymnasiumName",
      key: "gymnasiumName"
    },
    {
      title: "Branş",
      dataIndex: "sportsBranchName",
      key: "sportsBranchName"
    },
    {
      title: "Durum",
      key: "status",
      render: (record) => {
        let statusMessage;
        switch (record.status) {
          case "CHECKED_IN":
            statusMessage = "Check-in yapıldı";
            break;
          case "NOT_CHECKED_IN":
            statusMessage = "Check-in yapılmadı";
            break;
          case "NOT_YET_STARTED":
            statusMessage = "Henüz etkinlik başlamadı";
            break;
        }
        return <span>{statusMessage}</span>;
      }
    }
  ];

  const adminBreadCrumb = [
    {
      title: <Link to="/">Anasayfa</Link>
    },
    {
      title: <Link to="/check-in-raporlari">Check-in Raporları</Link>
    },
    {
      title: "Check-in Raporu"
    }
  ];

  const institutionBreadCrumb = [
    {
      title: <Link to="/">Anasayfa</Link>
    },
    {
      title: "Check-in Raporu"
    }
  ];

  return (
    <Flex vertical gap={20}>
      <Breadcrumb
        items={user.role === ROLES.ADMIN ? adminBreadCrumb : institutionBreadCrumb}
      />
      <Card title="Check-in Raporu">
        <Table
          dataSource={checkInsResponse?.data?.checkIns}
          columns={columns}
          scroll={{ x: 50 }}
          loading={isLoading}
          locale={{
            emptyText: <Empty description="Görüntülenecek check-in raporu yok" />,
          }}
        />
      </Card>
    </Flex>
  );
}

export default CheckIns;
