import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin
} from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gymnasiumService } from "../../services/gymnasiums";
import { reservationService } from "../../services/reservation";
import styles from "../Reservation/Reservation.module.scss";
import { getUserInfo } from "../../utils/getUserInfo";

function EditSportsClub() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { sportsClubId } = useParams();
  const [form] = useForm();
  const [isInstitutionEmpty, setIsInstitutionEmpty] = useState(true);
  const user = getUserInfo();

  const { data: branchsResponse } = useQuery({
    queryFn: () => reservationService.getBranchs(user.role),
    queryKey: ["branchs", user.role],
  });

  const {
    data: getSportsClubResponse,
    isLoading: getSportsClubLoading,
    refetch: getSportsClub,
    isError
  } = useQuery({
    queryFn: () => gymnasiumService.getSportsClub(sportsClubId),
    queryKey: ["sportsClub", sportsClubId],
    enabled: !!sportsClubId
  });

  const { mutate: updateSportsClub } = useMutation({
    mutationFn: gymnasiumService.updateSportsClub,
    onSuccess: () => {
      notification.success({
        message: "Spor kulübü güncellendi."
      });
      setLoading(true);
      getSportsClub();
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Spor kulübü güncellenirken bir hata oluştu!"
      });
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  const handleOnFinish = (values) => {
    const { name, phoneNumber, licenseNumber, sportsBranches } = values;
    const payload = {
      sportsClubId,
      name,
      phoneNumber,
      licenseNumber,
      sportsBranchIds: sportsBranches
    };
    setLoading(true);
    updateSportsClub({ sportsClubId, payload });
  };

  useEffect(() => {
    const sportsClub = getSportsClubResponse?.data;
    if (sportsClub) {
      form.setFieldsValue({
        ...sportsClub,
        sportsBranches: sportsClub.branches.map((item) => item.id)
      });
      const hasInstitution = sportsClub?.institutionId !== undefined;
      setIsInstitutionEmpty(!hasInstitution);
    }
  }, [form, getSportsClubResponse?.data]);

  useEffect(() => {
    if (isError) {
      notification.error({
        message: "Kurum getirilirken bir hata oluştu.",
        description: "Sistemde bir hata oluştu. Lütfen tekrar deneyin."
      });
      navigate("/kurum-spor-duzenleme");
    }
  }, [isError, navigate]);

  return (
    <Spin spinning={loading || getSportsClubLoading}>
      <Flex vertical gap={20}>
        <Breadcrumb
          items={[
            {
              title: <Link to="/">Anasayfa</Link>
            },
            {
              title: <Link to="/kurum-spor-duzenleme">Kurumlar</Link>
            },
            {
              title: "Spor Kulübü Güncelle"
            }
          ]}
        />
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={handleOnFinish}
        >
          <Card
            title="Spor Kulübü Bilgileri"
            extra={
              <Button htmlType="submit" type="primary">
                Kaydet
              </Button>
            }
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="Branş"
                  name="sportsBranches"
                  rules={[{ required: true, message: "Branş seçiniz." }]}
                >
                  <Select
                    showSearch
                    className={styles.select}
                    placeholder="Branş Seçiniz"
                    options={branchsResponse?.data.sportsBranches.map(
                      (branch) => ({
                        label: branch.name,
                        value: branch.id
                      })
                    )}
                    mode="multiple"
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label="Kulüp Numarası"
                  name="licenseNumber"
                  rules={[
                    { required: true, message: "Kulüp numarası giriniz." }
                  ]}
                >
                  <Input placeholder="Kulüp Numarası" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Kulüp Adı"
                  name="name"
                  rules={[{ required: true, message: "Kulüp adı giriniz." }]}
                >
                  <Input placeholder="Kulüp Adı" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="phoneNumber"
                  label="İrtibat Numarası"
                  rules={[
                    {
                      required: true,
                      message: "İrtibat numarası giriniz."
                    },
                    {
                      pattern: /^0\d{10}$/,
                      message:
                        "Lütfen 11 haneli bir irtibat numarası giriniz. Örnek: 05555555555"
                    }
                  ]}
                >
                  <Input
                    placeholder="İrtibat Numarası"
                    maxLength={11}
                    type="tel"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="email"
                  label="E-Posta"
                  rules={[
                    { required: true, message: "E-posta giriniz." },
                    { type: "email", message: "Geçerli bir e-posta giriniz." }
                  ]}
                >
                  <Input placeholder="E-Posta" disabled />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Flex>
    </Spin>
  );
}

export default EditSportsClub;
