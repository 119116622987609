import React, { useState } from "react";
import { Col, Row, Button, Form, Input, notification } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../services/auth";
import styles from "./ResetPassword.module.scss";
import logo from "../../assets/images/logo.png";

function ResetPassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Hook to get search parameters from URL
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { mutate: resetPassword } = useMutation({
    mutationFn: authService.resetPassword,
    onSuccess: () => {
      notification.success({
        message: "Başarılı!",
        description:
          "Şifreniz başarıyla sıfırlandı. Yeni şifrenizle giriş yapabilirsiniz."
      });
      setLoading(false);
      // Redirect to login page or home page after successful submission
      navigate("/");
    },
    onError: (err) => {
      notification.error({
        message: err.response?.data?.errors[0]?.message,
        description: "Şifre sıfırlama sırasında bir hata oluştu!",
      });
      setLoading(false);
    }
  });

  const onFinish = (values) => {
    setLoading(true);
    resetPassword({ ...values, token });
  };

  return (
    <div className={styles.resetPassword}>
      <div className={styles.overlay}></div>
      <div className={styles.container}>
        <Row>
          <Col md={12} xs={0}>
            <div className={styles.resetPasswordHero}>
              <h1>Hoş geldiniz</h1>
              <p>
                İstanbul İl Milli Eğitim Müdürlüğü
              </p>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className={styles.resetPasswordFormWrapper}>
              <div className={styles.resetPasswordForm}>
              <div className={styles.logo}>
                  <img src={logo} alt="logo" />
                </div>
                <h3 className={styles.title}>ŞİFREMİ SIFIRLA</h3>
                <div className={styles.formWrapper}>
                  <Form
                    name="resetPassword"
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="password"
                      label="Yeni Şifre"
                      rules={[
                        { required: true, message: "Yeni şifreyi giriniz." },
                        { min: 6, message: "Şifre en az 6 karakter olmalıdır." }
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      label="Yeni Şifre Tekrar"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        { required: true, message: "Yeni şifreyi tekrar giriniz." },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Şifreler birbiriyle eşleşmiyor.")
                            );
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                      >
                        Şifremi Sıfırla
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ResetPassword;
